const state = {
  homebodydata: "",
  aboutusdata: "",
  projectcatdata: "",
  projectlistdata: "",
  contactdata: "",
  productlist: {},
  pcatelist: {},
  productlisttype: "",
  fcatelist: {},
  fproductlisttype: "",
  totalproduct: 0,
  totalpage: 1,
  productdetails: {},
  familydetails: "",
  latestproject: {},
  generalinformation: {},
  downloadcategorylist: {},
  downloadfileslist: {},
  totaprojectpage: 1,
  projectdetails: {},
  downloadpageapidata: {},
  ProductFilterdata: {},
  variationproductfilteroption: {},
  variationproduct: {},
  totalvarproductpage: 1,
  totalvariableProduct: 0,
  downloaddatasheet: {},
  varaiableproductdatasheet: "",

  newslistdata: "",
  totalnewspage: 1,
  newsdetails: {},
  sustainabilitydata: "",
  myprojectlist: {},

  customlightdata: "",
  antimicrobialdata: "",
  humancentricdata: "",
  displaymessage: "",
  casambidata: "",
  commonpagedata: "",
  slaverypagesdata: "",
  myprojectdetails: {},
  stmaltopdfdata: {},
  productlistbycat: {},
  productvariationdata: {},
  variationproductdetails: {},
  variationpvariable: [],
  madeinbritaindata: {},
  thankyoudata: "",
  defultsvgfile: "",
  componentdata: "",
  specificationdata: "",
  productmenu: "",
  lightingdirection: "",
  productvarientstape: "",
  customizationstepdata: {},
  customisationhistory: "",
  taxonomidetails: {},
  filterproducttype: {},
  producttemplatetype: "",
  headersearchdata: null,
  productbannerdata: "",
  ceilingsfiresafetydata: "",
  useexportdata: "",
  commercialindustrialdata: "",
  ledgenpagedata: "",
  astrowaveproductdata:"",
};
export default state;