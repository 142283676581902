const mutations = {
  SET_HOMEBODYCONTENT: (state, homebodydata) => {
    state.homebodydata = homebodydata;
  },
  SET_ABOUTUSCONTENT: (state, aboutusdata) => {
    state.aboutusdata = aboutusdata;
  },
  SET_PROJECTCATEGORYCONTENT: (state, projectcatdata) => {
    state.projectcatdata = projectcatdata;
  },
  SET_PROJECTLISTCONTENT: (state, projectlistdata) => {
    state.projectlistdata = projectlistdata;
  },
  SET_CONTACTUSCONTENT: (state, contactdata) => {
    state.contactdata = contactdata;
  },

  SET_PRODUCTLIST: (state, productlist) => {
    state.productlist = productlist;
  },
  SET_PRODUCTLISTTYPE: (state, productlisttype) => {
    state.productlisttype = productlisttype;
  },
  SET_PCATELIST: (state, pcatelist) => {
    state.pcatelist = pcatelist;
  },

  SET_FPRODUCTLISTTYPE: (state, fproductlisttype) => {
    state.fproductlisttype = fproductlisttype;
  },

  SET_FCATELIST: (state, fcatelist) => {
    state.fcatelist = fcatelist;
  },

  SET_TOTALPRODUCTCOUNT: (state, totalproduct) => {
    state.totalproduct = totalproduct;
  },
  SET_PRODUCTNOOFPAGE: (state, totalpage) => {
    state.totalpage = totalpage;
  },

  SET_PRODUCTDETAILS: (state, productdetails) => {
    state.productdetails = productdetails;
  },

  SET_FAMILYDETAILS: (state, familydetails) => {
    state.familydetails = familydetails;
  },

  SET_LATESTPROJECT: (state, latestproject) => {
    state.latestproject = latestproject;
  },

  SET_GENERALINFORMATION: (state, generalinformation) => {
    state.generalinformation = generalinformation;
  },

  SET_DOWNLOADCATEGORYLIST: (state, downloadcategorylist) => {
    state.downloadcategorylist = downloadcategorylist;
  },

  SET_DOWNLOADFILESLIST: (state, downloadfileslist) => {
    state.downloadfileslist = downloadfileslist;
  },

  SET_TOTALPROJECTPAGE: (state, totaprojectpage) => {
    state.totaprojectpage = totaprojectpage;
  },
  SET_PROJECTDETAILS: (state, projectdetails) => {
    state.projectdetails = projectdetails;
  },

  SET_DOWNLOADPAGEDATA: (state, downloadpageapidata) => {
    state.downloadpageapidata = downloadpageapidata;
  },

  SET_PRODUCTFILTERDATA: (state, ProductFilterdata) => {
    state.ProductFilterdata = ProductFilterdata;
  },

  SET_VARIATIONPRODUCTFILTEROPTION: (state, variationproductfilteroption) => {
    state.variationproductfilteroption = variationproductfilteroption;
  },

  SET_VARIATIONPRODUCT: (state, variationproduct) => {
    state.variationproduct = variationproduct;
  },

  SET_VARIATIONPRODUCTTOTALPAGE: (state, totalvarproductpage) => {
    state.totalvarproductpage = totalvarproductpage;
  },

  SET_TOTALVARIATIONPRODUCT: (state, totalvariableProduct) => {
    state.totalvariableProduct = totalvariableProduct;
  },

  SET_VARIATION_PRODUCT_DATASHEET: (state, downloaddatasheet) => {
    state.downloaddatasheet = downloaddatasheet;
  },

  SET_VARIATION_PRODUCT_DATASHEET_DETAILS: (
    state,
    varaiableproductdatasheet
  ) => {
    state.varaiableproductdatasheet = varaiableproductdatasheet;
  },

  SET_TOTALNEWSPAGE: (state, totalnewspage) => {
    state.totalnewspage = totalnewspage;
  },

  SET_NEWSLISTCONTENT: (state, newslistdata) => {
    state.newslistdata = newslistdata;
  },

  SET_NEWSDETAILS: (state, newsdetails) => {
    state.newsdetails = newsdetails;
  },

  SET_SUSTAINABILITYCONTENT: (state, sustainabilitydata) => {
    state.sustainabilitydata = sustainabilitydata;
  },

  SET_MYPROJECTLIST: (state, myprojectlist) => {
    state.myprojectlist = myprojectlist;
  },

  SET_CUSTOMLIGHTCONTENT: (state, customlightdata) => {
    state.customlightdata = customlightdata;
  },

  SET_ANTIMICROBIALCONTENT: (state, antimicrobialdata) => {
    state.antimicrobialdata = antimicrobialdata;
  },

  SET_HUMANCENTRICCONTENT: (state, humancentricdata) => {
    state.humancentricdata = humancentricdata;
  },

  SET_CASAMBICONTENT: (state, casambidata) => {
    state.casambidata = casambidata;
  },

  SET_COMMONPAGECONTENT: (state, commonpagedata) => {
    state.commonpagedata = commonpagedata;
  },

  SET_SLAVERYPAGECONTENT: (state, slaverypagesdata) => {
    state.slaverypagesdata = slaverypagesdata;
  },

  SET_REQUESTDISPLAYMESSAGE: (state, displaymessage) => {
    state.displaymessage = displaymessage;
  },

  SET_MYPROJECTDETAILS: (state, myprojectdetails) => {
    state.myprojectdetails = myprojectdetails;
  },

  SET_HTMLTOPDFDATA: (state, stmaltopdfdata) => {
    state.stmaltopdfdata = stmaltopdfdata;
  },

  SET_PRODUCTLISTBYCATEGORY: (state, productlistbycat) => {
    state.productlistbycat = productlistbycat;
  },

  SET_PRODUCTVARIATIONDATA: (state, productvariationdata) => {
    state.productvariationdata = productvariationdata;
  },

  SET_VARIATIONPRODUCTDETAILS: (state, variationproductdetails) => {
    state.variationproductdetails = variationproductdetails;
  },

  SET_VARIATIONVARIABLE: (state, variationpvariable) => {
    state.variationpvariable = variationpvariable;
    // console.log('mutation console',variationpvariable);
  },

  SET_MADEINBRITAINDATA: (state, madeinbritaindata) => {
    state.madeinbritaindata = madeinbritaindata;
  },

  SET_THANKYOUDATA: (state, thankyoudata) => {
    state.thankyoudata = thankyoudata;
  },

  SET_SVGFILE: (state, defultsvgfile) => {
    state.defultsvgfile = defultsvgfile;
  },

  SET_COMPONENTDATA: (state, componentdata) => {
    state.componentdata = componentdata;
  },

  SET_SPECIFICATIONDATA: (state, specificationdata) => {
    state.specificationdata = specificationdata;
  },

  SET_PRODUCTMENUCONTENT: (state, productmenu) => {
    state.productmenu = productmenu;
  },

  SET_LIGHTINGDIRECTIONDATA: (state, lightingdirection) => {
    state.lightingdirection = lightingdirection;
  },

  SET_PRODUCTVARIENTSTAPECONTENT: (state, productvarientstape) => {
    state.productvarientstape = productvarientstape;
  },

  SET_CUSTOMIZATIONSTEPDATA: (state, customizationstepdata) => {
    state.customizationstepdata = customizationstepdata;
  },
  SET_CUSTOMISATIONHISTORY: (state, customisationhistory) => {
    state.customisationhistory = customisationhistory;
  },

  SET_PRODUCTTAXONOMIDETAILS: (state, taxonomidetails) => {
    state.taxonomidetails = taxonomidetails;
  },

  SET_FILTERPRODUCTTYPELIST: (state, filterproducttype) => {
    state.filterproducttype = filterproducttype;
  },

  SET_PODUCTTEMPLATETYPE: (state, producttemplatetype) => {
    state.producttemplatetype = producttemplatetype;
  },

  SET_HEADERSEARCHDATA(state, headersearchdata) {
    state.headersearchdata = headersearchdata;
  },

  SET_PRODUCTBANNERDATA(state, productbannerdata) {
    state.productbannerdata = productbannerdata;
  },

  SET_CEILINGFIRESEFTYDATA(state, ceilingsfiresafetydata) {
    state.ceilingsfiresafetydata = ceilingsfiresafetydata;
  },

  SET_UAEEXPORTDATA(state, useexportdata) {
    state.useexportdata = useexportdata;
  },

  SET_CMMERCIALINDUSTRIALDATA(state, commercialindustrialdata) {
    state.commercialindustrialdata = commercialindustrialdata;
  },

  SET_LEDGENPAGEDATA(state, ledgenpagedata){
    state.ledgenpagedata = ledgenpagedata;
  },

  SET_ASTROWAVEPRODUCTDETAILS(state, astrowaveproductdata){
    state.astrowaveproductdata = astrowaveproductdata;
  }
};
export default mutations;